import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Notification from "../components/Notification"

const Studien = () => (
  <Layout>
    <SEO title="TDCS Rosenheim" />

    <Notification />

    <h1>Studien</h1>

    <h2>Chronischer Schmerz</h2>
    <a href="https://clinicaltrials.gov/ct2/results?cond=tdcs+and+chronic+pain">
      Chronische Schmerzen
    </a>
    <p>
      Platzierung der transkraniellen elektrischen Stimulationselektrode zur
      Linderung chronischer Schmerzen
    </p>
    <img className="img" src="" alt="picture c4 c3" />
    <p>
      tDCS-Elektrodenplatzierungsmontage für chronische Plainaaaa <br />
      Anode: Ipsilesional (C3) oder Contralesional (C4)
      <br />
      Kathode: Gegenüber dem präfrontalen Kortex (FP2 oder FP1)
    </p>
    <p>
      Studienergebnisse: Die Mehrzahl der klinischen Studien konzentriert sich
      auf Schmerzen, die durch Fibromyalgie, Rückenmarksverletzung, Schmerzen im
      unteren Rückenbereich, Phantomgliedmaßen, Zerebralparese und chronischen
      Schlaganfall verursacht werden. Darüber hinaus wurden vielversprechende
      Ergebnisse bei diabetischer Polyneuropathie, chronischen Schmerzen nach
      Schlaganfall, dokumentiert.
    </p>
    <p>
      Anmerkungen: Am häufigsten wird die Anode (rote Elektrode) auf der
      gegenüberliegenden Seite des Schädels in Bezug auf den Ort des Schmerzes
      am Körper platziert (auch bekannt als „kontralateral“). Das Ausmaß der
      Schmerzreduktion scheint direkt mit der Dauer und Intensität der
      Stimulation verbunden zu sein. Wenn die tDCS-Sitzungslängen erhöht wurden,
      meldeten Benutzer bessere Ergebnisse. Gleiches gilt für das aktuell
      verwendete Level. Das 30-minütige Anlegen von 2 Milliampere Strom führte
      im gleichen Zeitraum zu einer größeren Erleichterung als 1 Milliampere.
    </p>
    <p>
      Wissenschaftliche Studien: Eine umfassende Datenbank veröffentlichter
      klinischer tDCS-Studien (2005–2016) <br />
      Ortsspezifische Effekte der transkraniellen Gleichstromstimulation auf
      Schlaf und Schmerzen bei Fibromyalgie: eine randomisierte,
      scheinkontrollierte Studie. <br />
      Auswirkungen auf die Abnahme des Muskeltonus der oberen Extremitäten nach
      dem Schlaganfall durch transkranielle Gleichstromstimulation: Eine
      randomisierte scheinkontrollierte Studie Ipsilesionales anodales tDCS
      verbessert die funktionellen Vorteile der Rehabilitation bei Patienten
      nach Schlaganfall
    </p>
    <p>Einzelne Testimonials: https://whatistdcs.com/tdcs-for-pain-relief/</p>

    <h2>Depression und Angst</h2>
    <p>
      Behandlung von Depressions- und Angstsymptomen mit transkranieller
      Gleichstromstimulation
    </p>
    <img className="img" src="" alt="picture f3" />

    <h2>Alternative Depressionsbehandlung</h2>
    <h2>Erhöhter Fokus, beschleunigtes Lernen</h2>
    <h2>Verbesserung der Aufmerksamkeit</h2>
    <h2>Verbesserte Einsicht</h2>
    <h2>Verbesserte Bewegungsmobilität und lehrnen</h2>
    <h2>Suchtbehandlung</h2>
    <h2>Verbesserte mathematische Fähigkeiten</h2>
    <h2>Erhöhtes gegenwärtiges Bewusstsein</h2>
    <h2>Reduzierte Risikobereitschaft</h2>
    <h2>Tonhöhenwahrnehmung</h2>

    <a href="http://www.totaltdcs.com">www.totaltdcs.com</a>
  </Layout>
)

export default Studien
